import { moveFacetedFilters } from './moveFacetedFilters.js';
import {getElementProperties, trackSegmentEvent} from "@/components/shared/tracking/segmentAnalytics";

document.addEventListener('DOMContentLoaded', function () {
  // make header sticky and attach faceted filters
  // activate_moveFacetedFilters = directory_page? && !hero_test && !disable_directory_filters_test
  let lastScrollPosition = 0;
  const generalNav = document.querySelector('[data-general-nav]');

  if (generalNav) {
    document.addEventListener('scroll', function () {

      // I am allowing pageYOffset to be less than 0 here because apparently on iphone 14 it can be less than 0
      if (window.pageYOffset > 0 && lastScrollPosition <= 0) {
        generalNav.classList.add('is-sticking');
        moveFacetedFilters(generalNav);
      } else if (window.pageYOffset <= 0 && lastScrollPosition > 0) {
        generalNav.classList.remove('is-sticking');
        moveFacetedFilters(generalNav);
      }

      if (window.pageYOffset > 0 && window.pageYOffset > lastScrollPosition) {
        generalNav.classList.add('hide-header');
      } else {
        generalNav.classList.remove('hide-header');
      }

      lastScrollPosition = window.pageYOffset;
    });
  }

  const closeAllAccordionSections = () => {
    const sectionButtons = document.querySelectorAll("[data-navigation-mobile-browse-section]");
    const subsections = document.querySelectorAll("[data-navigation-mobile-browse-subsection]");
    const subsectionButtons = document.querySelectorAll("[data-navigation-browse-subsection]");
    const subsectionLinks = document.querySelectorAll("[data-navigation-browse-section-links]");
    subsections.forEach(function (subsection) {
      subsection.classList.add('closed');
    })
    subsectionLinks.forEach(function (link) {
      link.classList.add('closed');
    })
    sectionButtons.forEach(function (link) {
      link.classList.remove("open")
    });
    subsectionButtons.forEach(function (button) {
      button.classList.remove("open")
    })
  }

  const toggleMegaMenu = () => {
    if (navigation.classList.contains('solv-header--open')) {
      closeAllAccordionSections();
    }
    navigation.classList.toggle('solv-header--open');
  }

  const openMegaMenu = () => {

    if (window.innerWidth < 768) {
      return;
    }
    navigation.classList.add('solv-header--open');
  }
  const closeMegaMenu = () => {
    if (window.innerWidth < 768) {
      return;
    }
    navigation.classList.remove('solv-header--open');
    closeAllAccordionSections();
  }


  const browseSection = document.querySelector("[data-navigation-browse]");
  const browseButtons = document.querySelectorAll("[data-navigation-browse-button]");
  const navigation = document.querySelector("[data-navigation]");
  const mobileMenuToggleButtons = document.querySelectorAll("[data-navigation-mobile-toggle]");
  const loginButton = document.querySelector("[data-login-button]");

  // only show the login button on the opening of the menu on mobile
  mobileMenuToggleButtons.forEach((button) => {
    button.addEventListener('click', (e) => {
      loginButton.classList.toggle('hidden');
      if (loginButton.classList.contains('hidden')) {
        trackSegmentEvent('Directory Header Links - Show Mobile Link Menu', {...getElementProperties(button)});
      } else {
        trackSegmentEvent('Directory Header Links - Close Mobile Link Menu', {...getElementProperties(button)});
      }
    });
  });

  browseButtons.forEach(function (button) {
    button.addEventListener('touchend', function (e) {
      toggleMegaMenu()
    });
    button.addEventListener('mouseenter', function (e) {
      openMegaMenu();
      trackSegmentEvent('Directory Header Links - Show Desktop Link Menu', {...getElementProperties(button)});
    });
    button.addEventListener('mouseleave', function (e) {
      closeMegaMenu()
      trackSegmentEvent('Directory Header Links - Close Desktop Link Menu', {...getElementProperties(button)});
    });
    browseSection.addEventListener('mouseenter', function (e) {
      openMegaMenu();
    });
    browseSection.addEventListener('mouseleave', function (e) {
      closeMegaMenu()
    });
  })



  const subsectionButtons = document.querySelectorAll("[data-navigation-browse-subsection]");

  subsectionButtons.forEach(function (button) {
    button.addEventListener('click', function (e) {

      const subsectionName = button.dataset.navigationBrowseSubsection;
      const subsectionLinks = document.querySelector(`[data-navigation-browse-subsection-links="${subsectionName}"]`);
      const sectionName = button.dataset.navigationBrowseSection;
      const allSubsectionLinks = document.querySelectorAll(`[data-navigation-browse-subsection-links]`);

      if (!subsectionLinks.classList.contains('closed')) {
        subsectionLinks.classList.add('closed');
        button.classList.remove('open')
        subsectionButtons.forEach(function (button) {
          button.classList.remove("open")
        })
      } else {
        allSubsectionLinks.forEach(function (link) {
          if (link.dataset.navigationBrowseSectionLinks === sectionName) {
            link.classList.add('closed');
          }
        });
        subsectionButtons.forEach(function (button) {
          button.classList.remove("open")
        })
        subsectionLinks.classList.remove('closed');
        button.classList.add('open')
      }
    });
  })

  const mobileSectionButtons = document.querySelectorAll("[data-navigation-mobile-browse-section]");

  mobileSectionButtons.forEach(function (button) {
    button.addEventListener('click', function (e) {

      const sectionName = button.dataset.navigationMobileBrowseSection;
      const subsections = document.querySelectorAll(`[data-navigation-mobile-browse-subsection="${sectionName}"]`);
      const allSubsections = document.querySelectorAll(`[data-navigation-mobile-browse-subsection]`);

      const firstSubsection = subsections[0]

      if (!firstSubsection.classList.contains('closed')) {
        subsections.forEach(function (subsection) {
          subsection.classList.add('closed');
        })
        mobileSectionButtons.forEach(function (button) {
          button.classList.remove("open")
        })
        button.classList.remove('open')
      } else {
        allSubsections.forEach(function (link) {
          link.classList.add('closed');
        });
        subsections.forEach(function (subsection) {
          subsection.classList.remove('closed');
        })
        mobileSectionButtons.forEach(function (button) {
          button.classList.remove("open")
        })
        button.classList.add('open')
      }
    });
  })

})
